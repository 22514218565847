import { Component, OnInit } from '@angular/core';
import {environment} from "../../environments/environment";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  allPhotos = environment.photoinfo
  allColl = environment.collinfo
  set: string;
  photos: any;
  photoArray: any;
  slideIndex: number = 0;
  imageAttributes: string[] = [];

  
 

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams
    .subscribe(params => {
      this.set = params.url;
    });
    for (let element of this.allPhotos) {
      if (element.url == this.set) {
        this.photos = element;
      }
    }
    for (let element of this.allColl) {
      if (element.url == this.set) {
        this.photos = element;
      }
    }
    
    this.photoArray = Array.from({length: this.photos.number}, (v, i) => i)
    
    this.showSlides(0);
    
  }


  


// Next/previous controls
plusSlides(n) {
  this.showSlides(this.slideIndex += n);
}



showSlides(n) {
  
  this.imageAttributes = []
  this.photoArray.forEach(element => {
    this.imageAttributes.push("none")
  });
  if (n <= -2){
    this.slideIndex = 6;
  }
  else if (n >= (this.photos.number -1)){
    this.slideIndex = -1;
  }
  else {
    this.slideIndex = n;
  }
  this.imageAttributes[this.slideIndex] = "block"

}
}
