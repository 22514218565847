import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'

@Component({
  selector: 'app-photo-collections',
  templateUrl: './photo-collections.component.html',
  styleUrls: ['./photo-collections.component.scss']
})
export class PhotoCollectionsComponent implements OnInit {

  constructor() { }
  allPhotos = environment.collinfo

  ngOnInit() {
  }

}
