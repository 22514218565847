// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  photoinfo: [
    {url: "landmark", title: "Landmark Exibition & Procession", year: "1982", number: "14"},
    {url: "booklaunch", title: "Book Launch", year: "2004", number: "6"}, 
    {url: "methodist", title: "Centennial Methodist Church", year: "2011-2012", number: "9"},
    {url: "selwyntown", title: "150th Selwyn Township Residents", year: "2012", number: "9"},
    {url: "selwyntownhistorical", title: "150th Selwyn Township Historial Societies", year: "2012", number: "6"},
    {url: "selwynschool", title: "150th Selwyn School", year: "2020", number: "3"},
    {url: "dunsandeltown", title: "150th Dunsandel Town", year: "2022", number: "8"},
],
collinfo: [
  {url: "ww1", title: "World War 1", number: "8"},
]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
