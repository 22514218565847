import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MajorEventsComponent } from './major-events/major-events.component';
import { PublicationsComponent } from './publications/publications.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { FooterComponent } from './components/footer/footer.component';
import { PhotoCollectionsComponent } from './photo-collections/photo-collections.component';
import { AdminComponent } from './admin/admin.component';
import { GalleryComponent } from './gallery/gallery.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MajorEventsComponent,
    PublicationsComponent,
    DownloadsComponent,
    FooterComponent,
    PhotoCollectionsComponent,
    AdminComponent,
    GalleryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
