import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'

@Component({
  selector: 'app-major-events',
  templateUrl: './major-events.component.html',
  styleUrls: ['./major-events.component.scss']
})
export class MajorEventsComponent implements OnInit {

  constructor() { }

  allPhotos = environment.photoinfo

  ngOnInit() {
  }

}
