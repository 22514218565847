import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DownloadsComponent } from './downloads/downloads.component'; 
import { PublicationsComponent } from './publications/publications.component'; 
import { MajorEventsComponent } from './major-events/major-events.component'; 
import { PhotoCollectionsComponent } from './photo-collections/photo-collections.component';
import { GalleryComponent } from './gallery/gallery.component';




const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'downloads', component: DownloadsComponent },
  { path: 'publications', component: PublicationsComponent },
  { path: 'events', component: MajorEventsComponent },
  { path: 'photos', component: PhotoCollectionsComponent },
  { path: 'gallery', component: GalleryComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
